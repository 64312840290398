<!--
  Form used to transfer lists to another user
-->

<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="768"
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col class="d-flex mt-n2">
                <div
                  class="d-inline-block align-self-start text-subtitle-1"
                  style="width: 80%"
                >
                  {{ $t('list.transfer.transferLists') }}
                </div>
                <div
                  class="d-inline-block text-right align-self-start"
                  style="width: 20%"
                >
                  <v-icon
                    class="mt-n2 mr-n2"
                    @click="hideDialog()"
                  >
                    mdi-close
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <!-- Warning text -->
          <v-card>
            <v-card-text>
              <v-icon class="warning--text">mdi-alert</v-icon>
              <span class="warning-style font-italic">
                {{ $t('list.transfer.warning') }}
              </span>
            </v-card-text>
          </v-card>

          <v-form
            ref="listTransferForm"
            v-model="valid"
            v-if="data"
            valid
          >
            <!-- Email address of the person to transfer the list(s) to -->
            <v-card class="mb-4 mt-5">
              <v-card-title class="text-subtitle-1">
                {{ $t('list.transfer.receivingUser') }}
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="transferTargetEmail"
                  auto-complete="off"
                  :rules="targetEmailRules"
                  :label="$t('list.transfer.transferTargetEmail')"
                  :error-messages="emailErrors"
                  @change="validateEmailValue(transferTargetEmail)"
                  required
                />
              </v-card-text>
            </v-card>

            <!-- The lists to transfer -->
            <v-card class="mb-4 mt-5">
              <v-card-title class="text-subtitle-1">
                {{ $t('list.transfer.listsToTransfer') }}
              </v-card-title>
              <v-card-text>
                <v-autocomplete
                  v-model="selectedLists"
                  :items="items"
                  :label="$t('list.transfer.selectedLists')"
                  :rules="listsRules"
                  item-text="title"
                  item-value="id"
                  multiple
                  dense
                  outlined
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="toggleAll"
                    >
                      <v-list-item-action>
                        <v-icon :color="selectedLists.length > 0 ? 'teal' : ''">
                          {{ iconAll }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('generic.selectAll') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>


                  <template v-slot:item="{ item, on, attrs }">
                    <template v-if="item.selectAllActive">
                      <v-list-item
                        ripple
                        @click="toggleActive"
                      >
                        <v-list-item-action>
                          <v-icon :color="selectedActiveLists.length > 0 ? 'teal' : ''">
                            {{ iconActive }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="grouptoggle primary--text text--darken-1">
                            {{ $t('list.transfer.selectAllActive') }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-else-if="item.selectAllArchived">
                      <v-list-item
                        ripple
                        @click="toggleArchived"
                      >
                        <v-list-item-action>
                          <v-icon :color="selectedArchivedLists.length > 0 ? 'teal' : ''">
                            {{ iconArchived }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="grouptoggle primary--text text--darken-1">
                            {{ $t('list.transfer.selectAllArchived') }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-else>
                      <v-list-item
                        v-on="on"
                        v-bind="attrs"
                        ripple
                      >
                        <v-list-item-action>
                          <v-icon :color="selectedLists.includes(item._id) ? 'teal' : ''">
                            {{ selectedLists.includes(item._id)
                              ? 'mdi-checkbox-marked'
                              : 'mdi-checkbox-blank-outline' }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.title }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </template>

                  <template v-slot:selection="{ index }">
                    <div
                      v-if="index === 0"
                      class="grey--text text-caption"
                    >
                      {{ selectedLists.length }} {{ $t('generic.selected') }}...
                    </div>
                  </template>
                </v-autocomplete>
              </v-card-text>
            </v-card>

            <!-- Buttons on main transfer dialog -->
            <div class="text-end mt-8 mr-n4 mb-n3">
              <v-btn
                class="mr-2"
                @click="hideDialog()"
              >
                {{ $t('generic.cancel') }}
              </v-btn>

              <v-btn
                color="teal lighten-1"
                class="white--text"
                @click="showConfirmationModal = true"
                :disabled="disabled"
                :loading="busy"
              >
                {{ $t('generic.ok') }}
              </v-btn>

              <!-- Confirmation modal -->
              <v-dialog
                v-if="showConfirmationModal"
                v-model="showConfirmationModal"
                persistent
                max-width="600"
              >
                <div>
                  <v-card class="confirmationModal">
                    <v-card-text>
                      <v-card>
                        <v-card-text>
                          <p class="warning-style font-italic" style="text-align: center;">
                            <i18n path="list.transfer.lastWarning1">
                              <template v-slot:amount><b>{{ selectedLists.length }}</b></template>
                              <template v-slot:target><b>{{ transferTargetEmail }}</b></template>
                            </i18n>
                          </p>
                          <p class="warning-style font-italic" style="text-align: center;">
                            {{ $t('list.transfer.lastWarning2') }}
                          </p>
                          <p class="warning-style font-italic" style="text-align: center;">
                            {{ $t('list.transfer.lastWarning3') }}
                          </p>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                    <div class="confirmationModalButtons">
                    <v-btn
                      class="mr-2"
                      @click="showConfirmationModal = false"
                    >
                      {{ $t('generic.cancel') }}
                    </v-btn>
                    <v-btn
                      color="teal lighten-1"
                      class="white--text"
                      @click="showConfirmationModal = false; submitForm()"
                    >
                      {{ $t('generic.ok') }}
                    </v-btn>
                  </div>
                  </v-card>
                </div>
              </v-dialog>
              <!-- End of confirmation modal -->
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { hideDialog } from '@/helpers/dialogHelper';

export default {
  name: 'ListManagerTransferForm',

  props: {
    data: {
      type: Object,
      default() {
        return {
          listsActive: [],
          listsArchived: [],
        };
      },
    },
  },

  data() {
    return {
      selectedLists: [],
      transferTargetEmail: null,
      targetEmailRules: [
        (v) => !!v || this.$t('list.transfer.transferTargetEmailMandatory'),
        // Backend email validation done through @change / error-messages property
      ],
      listsRules: [
        (v) => !!(v && v.length) || this.$t('list.transfer.invalidLists'),
      ],
      emailErrors: [],
      valid: false,
      dialog: true,
      busy: false,
      disabled: false,
      showConfirmationModal: false,
      formLabelWidth: '95px',
    };
  },

  computed: {
    emailValue() {
      return this.transferTargetEmail;
    },

    allLists() {
      return [...this.data.listsActive, ...this.data.listsArchived];
    },

    items() {
      const items = [];
      if (this.data.listsActive.length > 0) {
        items.push(...[
          { header: this.$t('list.active') },
          { selectAllActive: true },
          { divider: true },
          ...this.data.listsActive,
        ]);
      }
      if (this.data.listsArchived.length > 0) {
        items.push(...[
          { header: this.$t('list.archive') },
          { selectAllArchived: true },
          { divider: true },
          ...this.data.listsArchived,
        ]);
      }
      return items;
    },

    activeListIds() {
      return this.data.listsActive.map((list) => list._id);
    },
    archivedListIds() {
      return this.data.listsArchived.map((list) => list._id);
    },

    selectedActiveLists() {
      return this.selectedLists.filter((id) => this.activeListIds.includes(id));
    },
    selectedArchivedLists() {
      return this.selectedLists.filter((id) => this.archivedListIds.includes(id));
    },

    allListsChecked() {
      return this.selectedLists.length === this.allLists.length;
    },
    someListsChecked() {
      return this.selectedLists.length > 0 && !this.allListsChecked;
    },

    allActiveListsChecked() {
      return this.selectedActiveLists.length === this.data.listsActive.length;
    },
    someActiveListsChecked() {
      return this.selectedActiveLists.length > 0 && !this.allActiveListsChecked;
    },

    allArchivedListsChecked() {
      return this.selectedArchivedLists.length === this.data.listsArchived.length;
    },
    someArchivedListsChecked() {
      return this.selectedArchivedLists.length > 0 && !this.allArchivedListsChecked;
    },

    iconAll() {
      if (this.allListsChecked) return 'mdi-close-box';
      if (this.someListsChecked) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },

    iconActive() {
      if (this.allActiveListsChecked) return 'mdi-close-box';
      if (this.someActiveListsChecked) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },

    iconArchived() {
      if (this.allArchivedListsChecked) return 'mdi-close-box';
      if (this.someArchivedListsChecked) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },

  watch: {
    emailValue() {
      this.emailErrors = [];
    },
  },

  methods: {
    // https://stackoverflow.com/questions/49132167/how-to-validate-vuetify-text-field-asynchronously
    async validateEmailValue(emailValueToValidate) {
      try {
        this.emailErrors = [this.$t('list.transfer.checkingEmail')];
        const res = await this.$store.dispatch('verifyTransferEmail', emailValueToValidate);
        // If value has been changed since start of function, stop to avoid race condition issues
        if (emailValueToValidate !== this.emailValue) return;
        if (res.data.valid) {
          this.emailErrors = [];
        } else {
          this.emailErrors = [this.$t('list.transfer.invalidEmail')];
        }
      } catch (e) {
        this.emailErrors = [this.$t('list.transfer.invalidEmail')];
      }
    },

    toggleAll() {
      this.$nextTick(() => {
        if (this.allListsChecked) {
          this.selectedLists = [];
        } else {
          const list = [];
          this.allLists.slice().forEach((item) => {
            list.push(item.id);
          });
          this.selectedLists = list;
        }
      });
    },

    toggleActive() {
      this.$nextTick(() => {
        const list = [];
        if (!this.allActiveListsChecked) {
          this.data.listsActive.slice().forEach((item) => {
            list.push(item.id);
          });
        }
        this.selectedLists = [...list, ...this.selectedArchivedLists];
      });
    },

    toggleArchived() {
      this.$nextTick(() => {
        const list = [];
        if (!this.allArchivedListsChecked) {
          this.data.listsArchived.slice().forEach((item) => {
            list.push(item._id);
          });
        }
        this.selectedLists = [...this.selectedActiveLists, ...list];
      });
    },

    hideDialog,

    async submitForm() {
      // Make sure we check the email first, to update this.emailErrors
      // (@change of email field is skipped when hitting "OK" right after changing the email address)
      await this.validateEmailValue(this.transferTargetEmail);

      if (this.$refs.listTransferForm.validate()
        && Array.isArray(this.emailErrors)
        && this.emailErrors.length === 0) {
        this.busy = true;
        this.disabled = true;
        const transferData = {
          email: this.transferTargetEmail,
          listIds: this.selectedLists,
          successString: this.$t('list.transfer.listsSuccessfullyTransferred'),
        };
        await this.$store.dispatch('transferLists', transferData);
        this.hideDialog();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.warning-style {
  color: #f56c6c;
  font-size: 12px;
}
.grouptoggle {
  font-style: italic;
}

.confirmationModal {
  padding: 1em;
}

.confirmationModalButtons {
  width: 100%;
  text-align: right;

  .v-btn {
    margin-left: 8px;
  }
}
</style>
