<!--
  Form used to save, add and edit lists
-->

<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="768"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col class="d-flex mt-n2">
                <div
                  class="d-inline-block align-self-start text-subtitle-1"
                  style="width: 80%"
                >
                  {{ $t('list.form.formSaveList') }}
                </div>
                <div
                  class="d-inline-block text-right align-self-start"
                  style="width: 20%"
                >
                  <v-icon
                    class="mt-n2 mr-n2"
                    @click="hideDialog()"
                  >
                    mdi-close
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="listAddForm"
            v-model="valid"
            v-if="data"
            valid
          >
            <!-- eslint-disable vue/no-mutating-props -->
            <v-text-field
              v-model="data.title"
              auto-complete="off"
              :rules="titleRules"
              :label="$t('generic.title')"
              required
            />

            <v-textarea
              v-model="data.description"
              auto-complete="off"
              :label="$t('generic.description')"
              rows="1"
              row-height="24"
            />
            <!-- eslint-enable vue/no-mutating-props -->
            <div class="text-end mt-8 mr-n4 mb-n3">
              <v-btn
                class="mr-2"
                @click="hideDialog()"
              >
                {{ $t('generic.cancel') }}
              </v-btn>

              <v-btn
                color="teal lighten-1"
                class="white--text"
                @click="submitForm()"
                :disabled="disabled"
                :loading="busy"
              >
                {{ $t('generic.save') }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { hideDialog } from '@/helpers/dialogHelper';

export default {
  name: 'ListManagerForm',

  props: {
    data: {
      type: Object,
      default() {
        return { title: null, description: null };
      },
    },
  },

  data() {
    return {
      valid: false,
      dialog: true,
      titleRules: [
        (v) => !!v || this.$t('generic.titleMandatory'),
      ],
      busy: false,
      disabled: false,
      formLabelWidth: '150px',
    };
  },

  methods: {
    hideDialog,

    async submitForm() {
      if (this.$refs.listAddForm.validate()) {
        this.busy = true;
        this.disabled = true;

        await this.$store.dispatch('saveList', this.data);

        this.hideDialog();
      }
    },
  },
};
</script>
