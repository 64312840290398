<!--
  Form used to save, add and edit list items from a list
-->

<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div>
    <v-dialog
      v-if="listCurrent"
      v-model="dialog"
      max-width="768"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col class="d-flex mt-n2">
                <div
                  class="d-inline-block align-self-start text-subtitle-1"
                  style="width: 80%"
                >
                  {{ $t('list.form.formSaveUpdate') }}
                </div>
                <div
                  class="d-inline-block text-right align-self-start"
                  style="width: 20%"
                >
                  <v-icon
                    class="mt-n2 mr-n2"
                    @click="cancelDialog()"
                  >
                    mdi-close
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="listItemForm"
            v-model="valid"
            v-if="data"
            valid
          >
            <v-text-field
              v-model="data.title"
              auto-complete="off"
              :rules="titleRules"
              :label="`${$t('generic.title')}*`"
              required
            />

            <template>
              <div>
                <!-- Use the component in the right place of the template -->
                <div class="text-caption mb-1">{{ $t('generic.description') }}</div>
                <tiptap-vuetify
                  v-model="data.description"
                  :extensions="extensions"
                />
              </div>
            </template>

            <div v-if="data.createCustom || data.customDocument">
              <v-text-field
                v-model="data.customDocument.sourceName"
                auto-complete="off"
                :rules="customSourceNameRules"
                :label="$t('list.form.sourceName')"
              />

              <v-text-field
                v-model="data.customDocument.sourceUrl"
                auto-complete="off"
                :label="$t('list.form.sourceUrl')"
              />

              <v-menu
                v-model="customDateMenu"
                :close-on-content-click="false"
                min-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedDate"
                    clearable
                    :label="$t('generic.date')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="data.customDocument.date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data.customDocument.date"
                  @change="changeDate"
                  no-title
                ></v-date-picker>
              </v-menu>
            </div>

            <div>
              <v-select
                v-if="filteredListActive"
                :label="$t('list.addToOtherLists')"
                multiple
                v-model="data.lists"
                :items="filteredListActive"
                item-text="title"
                item-value="id"
                deletable-chips
                small-chips
                required
                :rules="listsRules"
                :no-data-text="$t('list.noSavedLists')"
              />
            </div>

            <div class="text-end mt-8 mr-n4 mb-n3">
              <v-btn
                class="mr-2"
                @click="cancelDialog()"
              >
                {{ $t('generic.cancel') }}
              </v-btn>

              <v-btn
                color="teal lighten-1"
                class="white--text"
                @click="submitForm()"
                :disabled="disabled"
                :loading="busy"
              >
                {{ $t('generic.save') }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  <!-- eslint-enable vue/no-mutating-props -->
</template>

<script>
import {
  TiptapVuetify,
  Bold,
  Italic,
  BulletList,
  ListItem,
} from 'tiptap-vuetify';
import { hideDialog } from '@/helpers/dialogHelper';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'ListManagerItemForm',

  components: {
    TiptapVuetify,
  },

  props: {
    data: {
      type: Object,
      default() {
        return {
          title: null,
          description: null,
          customDocument: {
            sourceName: null,
            sourceUrl: null,
            date: null,
          },
        };
      },
    },
  },

  data() {
    return {
      notRequired: false,
      extensions: [
        Bold,
        Italic,
        ListItem,
        BulletList,
      ],
      valid: false,
      dialog: true,
      date: this.data?.customDocument?.date || null,
      titleRules: [
        (v) => !!v || this.$t('list.form.updateTitleMandatory'),
      ],
      customSourceNameRules: [
        (v) => !!v || this.$t('list.form.updateCustomSourceNameMandatory'),
      ],
      listsRules: [
        (v) => v.length > 0 || this.$t('list.selectMinimum'),
      ],
      busy: false,
      disabled: false,
      formLabelWidth: '95px',
      customDateMenu: false,
    };
  },

  computed: {
    ...mapGetters({
      listsActive: 'listsActive',
      listCurrent: 'listCurrent',
    }),

    formattedDate() {
      if (this.date) {
        return moment(this.date).format('DD-MM-YYYY');
      }
      return null;
    },

    filteredListActive() {
      const list = [...this.listsActive];
      list.forEach((obj) => {
        if (obj.id === this.listCurrent.id) {
          obj.disabled = true;
        } else {
          obj.disabled = false;
        }
      });
      return list;
    },
  },

  methods: {
    hideDialog,

    changeDate(value) {
      this.date = value;
      this.customDateMenu = false;
    },

    resetFormData() {
      // eslint-disable-next-line vue/no-mutating-props
      this.data = {};
    },

    cancelDialog() {
      if (!this.data.createCustom) {
        this.$store.dispatch('getList', this.listCurrent.id);
      }
      this.resetFormData();
      this.hideDialog();
    },

    async submitForm() {
      if (this.$refs.listItemForm.validate()) {
        this.busy = true;
        this.disabled = true;

        const cleanedLists = this.data.lists.filter((item) => item !== this.listCurrent.id);
        const params = {
          listItem: this.data,
          copyToLists: cleanedLists,
        };

        await this.$store.dispatch('saveListItem', params);

        if (this.data.createCustom) {
          await this.$store.dispatch('getList', this.listCurrent.id);
        }
        this.resetFormData();
        this.hideDialog();
      }
    },
  },

};
</script>

<style lang="scss" scoped>
// For some stupid reason there are p elements placed inside an li element
// in tiptap-vuetify. Lets get rid of the white space
::v-deep {
  .tiptap-vuetify-editor__toolbar {
    header.theme--dark {
      background-color: #212121 !important;
    }
  }
  .tiptap-vuetify-editor__content {
    margin-bottom: 16px;
    p {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

}
</style>
